import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';
import { parseNFButton } from 'components/common/NFButton/parsers';
import SimplePopup from 'components/SimplePopup';
import RelatedElementsCarousel, {
  IRelatedElementsCarouselCalculated,
  IRelatedElementsCarouselData,
} from 'components/RelatedElementsCarousel';
import RelatedElementsList from 'components/RelatedElementsList';
import UniversalImage from 'components/common/UniversalImage';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { gtmService } from 'services/gtmService';

import { IMeltletsProductProps } from './models';
import Helpers from './helpers';
import {
  WindowPadding,
  RelatedElementsTriggerId,
  DefaultItemWidth,
  MobileViewRelatedElements,
} from './constants';

import './MeltletsProduct.scss';
import './MeltletsProductOverride.scss';

const MeltletsProduct: FC<IMeltletsProductProps> = ({
  meltletsProduct,
  carouselControls,
  selectedProduct,
}) => {
  const {
    properties: {
      sectionTitle,
      title,
      text,
      productImageAlt,
      relatedElementsTrigger,
      relatedElements,
    },
  } = meltletsProduct[0];
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
  const [isShowScrollOnRelatedElements, setShowScrollOnRelatedElements] = useState<boolean>(false);
  const [responsiveObj, setResponsiveObj] = useState<IRelatedElementsCarouselCalculated>({
    desktop: 0,
  });

  const { isDesktop, isLargeDesktop, isMobile, windowWidth } = useScreenRecognition();

  const handleCalculatedResponsiveObj = useCallback(
    (isDesktopMode: boolean | null, currentWidth: number) => () => {
      if (isDesktopMode) {
        setResponsiveObj({
          desktop: Math.floor((currentWidth - WindowPadding) / DefaultItemWidth),
        });
      }
    },
    []
  );

  const handleRelatedElementsVisibilityChange = useCallback(() => {
    setPopupVisible((oldValue: boolean) => {
      return !oldValue;
    });
  }, []);

  const popupTriggerData = relatedElementsTrigger[0].properties;
  const popupTriggerBtnData = popupTriggerData?.relatedElementsTriggerCTA?.[0]?.properties
    ? parseNFButton(popupTriggerData?.relatedElementsTriggerCTA?.[0]?.properties)
    : undefined;
  const popupTriggerIcon = popupTriggerData?.relatedElementsTriggerCTAIcon;
  const popupTriggerActiveText = popupTriggerData?.relatedElementsTriggerActiveText;
  const popupListProps: IRelatedElementsCarouselData | undefined = useMemo(
    () => Helpers.getRelatedElementsListProps(relatedElements, carouselControls),
    []
  );
  const productData = selectedProduct.nodes[0];

  const handleResizePopupList = useCallback(() => {
    Helpers.resizeRelatedElementsList(
      isMobile,
      setShowScrollOnRelatedElements,
      popupListProps?.items.length
    );
  }, [popupListProps, isMobile]);

  useEffect(() => {
    if (isPopupVisible) {
      handleResizePopupList();
    }
  }, [isPopupVisible, windowWidth, handleResizePopupList]);

  useEffect(() => {
    handleCalculatedResponsiveObj(isDesktop || isLargeDesktop, windowWidth)();
  }, [windowWidth, isDesktop, isLargeDesktop]);

  useEffect(() => {
    gtmService.emitProductView(productData);
  }, []);

  const handleRetailerClick = useCallback(
    (product: ProductCard.IProductCard) => (shopName: string) => {
      gtmService.emitRetailerClick(product, shopName);
    },
    []
  );

  return (
    <div data-test="MeltletsProduct" className="meltlets-product">
      <Container className="meltlets-product__container">
        <h2 className="meltlets-product__title">{sectionTitle}</h2>
        <div className="meltlets-product__card">
          <h3 className="meltlets-product__card-title">
            {title || productData?.defaultProductTitle}
          </h3>
          {productData.defaultProductImage?.gatsbyImage && (
            <UniversalImage
              img={productData.defaultProductImage}
              imageAlt={productImageAlt}
              wrapperClassName="nf-card__img type--img"
              imgStyle={{ objectFit: 'contain' }}
              objectFitData={{ objectFit: 'contain' }}
              withDirectionClassName
              isLazyLoading
            />
          )}
          {popupTriggerBtnData ? (
            <div className="meltlets-product__card-btn">
              <NFButton
                {...popupTriggerBtnData}
                className="related-map-view-btn related-elements-trigger"
                icon={popupTriggerIcon}
                onClick={handleRelatedElementsVisibilityChange}
                customElement={isPopupVisible ? <span className="trigger-triangle" /> : null}
                btnText={isPopupVisible ? popupTriggerActiveText : popupTriggerBtnData.btnText}
                btnStyles={Helpers.getButtonStyles(isPopupVisible, popupTriggerBtnData)}
                id={RelatedElementsTriggerId}
              />
            </div>
          ) : null}
        </div>

        {popupListProps && isPopupVisible ? (
          <SimplePopup
            className={classNames('related-elements-popup', {
              'with-scroll': isShowScrollOnRelatedElements,
            })}
            closeButtonTitle={popupListProps.closeButtonTitle}
            closeButtonAriaLabel={popupListProps.closeButtonAriaLabel}
            handleVisibilityChange={handleRelatedElementsVisibilityChange}
            clickOutsideExceptionsIds={[RelatedElementsTriggerId]}
            withCloseBtn
          >
            {windowWidth <= MobileViewRelatedElements ? (
              <RelatedElementsList
                {...popupListProps}
                retailerClickHandler={handleRetailerClick(productData)}
              />
            ) : (
              <RelatedElementsCarousel
                {...popupListProps}
                responsiveObjCalculated={responsiveObj}
                retailerClickHandler={handleRetailerClick(productData)}
              />
            )}
          </SimplePopup>
        ) : null}
        <DangerouslySetInnerHtml html={text} className="meltlets-product__text" />
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentMeltletsProduct on TMeltletsProduct {
    properties {
      sectionTitle
      title
      text
      productImageAlt
      relatedElementsTrigger {
        properties {
          ...FragmentRelatedElementsTrigger
        }
      }
      relatedElements {
        properties {
          ...FragmentRelatedElementsList
        }
      }
    }
  }
`;

export default MeltletsProduct;
